<template>
	<TitleBar :isChat="true" title="哄哄生气的女朋友！" :isBlank="true"
		@goBack="goBack"
		@clearConversation="clearConversation"
		@download="onDownload"
	>{{ intro }}</TitleBar>
	<div class="conversation scrollable">
		<ChatBox v-for="item in list" ref="chatBox"
			:key="item[2]" :item="item" :userImg="userImg" :avatarImg="avatarImg"
			@updated="updatePosition" @chooseAIHint="onInput"
		/>
	</div>
	<Inputter ref="inputter" @onInput="onInput" />
	<div class="bottom"></div>
</template>

<style scoped>
.titlebar {
	position: absolute;
	z-index: 1;
	top: 0px;
	width: 100%;
}
.conversation {
	position: absolute;
	z-index: 0;
	top: 64px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 75px;
}
.bottom {
	position: absolute;
	z-index: 1;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 20px;
	background-color: rgb(49, 51, 57);
	background: linear-gradient(to top, rgba(49, 51, 57, 1.0) 0%, rgba(49, 51, 57, 0.75) 50%, rgba(49, 51, 57, 0.0));
}
</style>

<script>
// @ is an alias to /src
import TitleBar from '@/components/titleBar.vue';
import ChatBox from '@/components/chat.vue';
import Inputter from '@/components/inputter.vue';

const GameID = 'GirlFriend';
const simpleParse = line => {
	line = line.replace(/\*\*([\w\W]+?)\*\*/g, (m, inner) => {
		return '[bold:]' + inner + '[:bold]';
	}).replace(/__([\w\W]+?)__/g, (m, inner) => {
		return '[bold:]' + inner + '[:bold]';
	}).replace(/\*([\w\W]+?)\*/g, (m, inner) => {
		return '[italic:]' + inner + '[:italic]';
	}).replace(/_([\w\W]+?)_/g, (m, inner) => {
		return '[italic:]' + inner + '[:italic]';
	}).replace(/\!\[([\w\W]*?)\]\s*\(([\w\W]+?)\)/g, (m, title, url) => {
		return '{img:' + url + '}';
	}).replace(/\[([\w\W]*?)\]\s*\(([\w\W]+?)\)/g, (m, title, url) => {
		return '<a target="_blank" href="' + url + '">' + title + '</a>';
	});

	return line;
};

var current;

export default {
	name: GameID,
	components: {
		TitleBar,
		ChatBox,
		Inputter
	},
	data () {
		return {
			ego: '',
			intro: '',
			list: [],
			userImg: '',
			avatarImg: '',
		}
	},
	async mounted () {
		current = this;
		document.title = 'AIVerse - 哄哄你生气的女朋友';

		// 获取自身唯一ID
		var ego = await DataCenter.get(DBNames.record, 'self', 'ego');
		if (!ego) {
			ego = newID(16);
			await DataCenter.set(DBNames.record, 'self', 'ego', ego);
		}
		this.ego = ego;
		console.log(this.ego);

		// 获取当前个人信息的更新记录
		var me = await DataCenter.get(DBNames.record, 'self', 'me');
		if (!me) {
			me = {
				logo: '/avatar/user.png',
				update: 1,
			};
		}
		if (!me.update) me.update = 1;

		this.$refs.inputter.inactive();
		try {
			let result = await sendRequest('/games', {
				event: 'info',
				gid: GameID,
				ego,
				update: me.update,
			});
			this.intro = result.intro;

			if (result.needUpdate) {
				let ok = await sendRequest('/games', {
					event: 'updateUserInfo',
					ego,
					info: me
				});
				if (ok) {
					notify({
						title: "后端已经更新个人信息",
						duration: 5000,
						type: "message"
					});
				}
			}
		}
		catch (err) {
			console.error(err);
			return;
		}

		if (!!me.logo.match(/^(ht|f)tps?/)) {
			this.userImg = 'background-image: url("' + me.logo + '")';
		}
		else {
			this.userImg = 'background-image: url("' + staticHost + me.logo + '")';
		}

		// 监听后台传回的数据
		global.onAvatarReply = async msg => {
			if (current !== this) return;

			console.log(msg);
			if (!!msg.model.match(/claude/i)) {
				if (!!msg.reply.reply) msg.reply.reply = convertClaudeChinese(msg.reply.reply);
				if (!!msg.reply.action) msg.reply.action = convertClaudeChinese(msg.reply.action);
				if (!!msg.reply.emotion) msg.reply.emotion = convertClaudeChinese(msg.reply.emotion);
			}
			delete msg.reply.target;
			msg.reply.extra = msg.reply.extra || {};
			msg.reply.extra.name = this.conversation.info.info.name;
			msg.reply.image = 'background-image: url("' + staticHost + '/avatar/' + this.conversation.info.info.logo + '")'
			this.addChat(1, msg.reply);

			var hint = [], value, target, changed = false;
			value = msg.reply.sad * 1;
			target = this.conversation.current.sad;
			if (!isNaN(value)) {
				if (value > target) {
					hint.push('更伤心了');
				}
				if (value !== target) {
					changed = true;
					this.conversation.current.sad = value;
				}
			}
			value = msg.reply.angry * 1;
			target = this.conversation.current.angry;
			if (!isNaN(value)) {
				if (value > target) {
					hint.push('更生气了');
				}
				if (value !== target) {
					changed = true;
					this.conversation.current.angry = value;
				}
			}
			value = msg.reply.wronged * 1;
			target = this.conversation.current.wronged;
			if (!isNaN(value)) {
				if (value > target) {
					hint.push('更委屈了');
				}
				if (value !== target) {
					changed = true;
					this.conversation.current.wronged = value;
				}
			}
			value = msg.reply.happy * 1;
			target = this.conversation.current.happy;
			if (!isNaN(value)) {
				if (value > target) {
					hint.push('更开心了');
				}
				if (value !== target) {
					changed = true;
					this.conversation.current.happy = value;
				}
			}
			value = msg.reply.forgive * 1;
			target = this.conversation.current.forgive;
			if (!isNaN(value)) {
				if (value < target) {
					hint.push('更无法原谅你了');
				}
				if (value !== target) {
					changed = true;
					this.conversation.current.forgive = value;
				}
			}
			if (value === 0) {
				hint.push("你们的感情已经无法符合，" + this.conversation.info.info.name + '选择了分手……');
			}
			else if (value === 100) {
				hint.push("你成功挽回了" + this.conversation.info.info.name + '，你们又可以继续在一起了！');
			}

			if (changed) {
				await DataCenter.set(DBNames.avatar, 'history', GameID, this.conversation);
			}

			hint = '态度变化：' + hint.join('；');
			this.addChat(5, hint, true);
		};

		var conversation = await DataCenter.get(DBNames.avatar, 'history', GameID);
		if (!conversation) {
			try {
				let result = await sendRequest('/games', {
					event: 'restart',
					gid: GameID,
					ego,
				});
				if (!result.ok) {
					console.error(result.message);
					notify({
						title: "重新开始游戏失败！",
						message: result.message,
						duration: 3000,
						type: "failed"
					});
					return;
				}
				conversation = {};
				conversation.history = [];
				conversation.info = result.data;
				await DataCenter.set(DBNames.avatar, 'history', GameID, conversation);
				conversation.current = {};
				for (let key in conversation.info.scores) {
					conversation.current[key] = conversation.info.scores[key];
				}
				this.conversation = conversation;
				this.addChat(5, '场景描述：\n' + conversation.info.desc);
			}
			catch (err) {
				console.error(err);
				notify({
					title: "重新开始游戏失败！",
					duration: 3000,
					type: "failed"
				});
				return;
			}
		}
		else {
			this.conversation = conversation;
			this.list.push(...conversation.history);
		}
		console.log(conversation);

		this.avatarImg = 'background-image: url("' + staticHost + '/avatar/' + conversation.info.info.logo + '")'

		this.$refs.inputter.active();
		this.$refs.inputter.focusInput();

		await wait(100);
		this.updatePosition();
	},
	unmounted () {
		if (current === this) current = null;
	},
	methods: {
		goBack () {
			this.$router.push({ path: '/' });
		},
		onInput (content) {
			content = this.parseInput(content);
			if (!content) return;
			this.addChat(0, content);
			this.appendChat(content);
		},
		parseInput (text) {
			var actions = [], emotion, environment;
			// 解析动作
			text = text.replace(/[\n\r]*\((动作|action)\s*[:：]\s*([\w\W]+?)\s*\)[\n\r]*|[\n\r]*（(动作|action)\s*[:：]\s*([\w\W]+?)\s*）[\n\r]*/gi, (m, u1, action1, u2, action2) => {
				actions.push(action1 || action2);
				return '\n';
			});
			// 解析情绪
			text = text.replace(/[\n\r]*\((表情|神态|情绪|expressions?|demeanors?|moods?|emotions?)\s*[:：]\s*([\w\W]+?)\s*\)[\n\r]*|[\n\r]*（(表情|神态|情绪|expressions?|demeanors?|moods?|emotions?)\s*[:：]\s*([\w\W]+?)\s*）[\n\r]*/gi, (m, u1, emotion1, u2, emotion2) => {
				emotion = emotion || emotion1 || emotion2;
				return '\n';
			});
			text = text.replace(/^\s*|\s*$/g, '');
			var input = { content: text };
			if (actions.length > 0) {
				input.action = actions.join('\n');
			}
			if (!!emotion) {
				input.emotion = emotion;
			}
			if (!!environment) {
				input.environment = environment;
			}
			return input;
		},
		async appendChat (text) {
			this.$refs.inputter.reset();
			this.$refs.inputter.focusInput();

			try {
				let result = await sendRequest('/games', {
					event: 'append',
					gid: GameID,
					content: text,
					ego: this.ego,
				});
				if (!result.ok && result.message === "no such aigf configuration") {
					notify({
						title: "配置出错，正在重启游戏！",
						duration: 5000,
						type: "warn"
					});
					await DataCenter.del(DBNames.avatar, 'history', GameID);
					location.reload();
				}
			}
			catch (err) {
				console.error(err);
				this.addChat(3, err.message || err.msg || err.data || err);
			}

			this.updatePosition();
		},
		addChat (type, content, saveHistory=true, rid) {
			var now = Date.now();
			var id = newID(16);
			if (type === 4 && !!rid) id = rid;
			var item = [type, content, id];
			this.list.push(item);
			if (type !== 4) {
				this.conversation.history.push(item);
				this.conversation.time = now;
				if (saveHistory) {
					DataCenter.set(DBNames.avatar, 'history', GameID, this.conversation);
				}
			}
			return id;
		},
		removeRunningHint (rid, exclusive=true) {
			if (!this.$refs.chatBox) return;
			this.$refs.chatBox.forEach((box) => {
				if (!box.isRunning) return;
				if ((box.index === rid) === exclusive) return;
				box.$el.style.display = 'none';
				box.isRunning = false;
			});
		},
		updatePosition () {
			if (!this.$refs.chatBox || !this.$refs.chatBox.length) return;
			this.$refs.chatBox.forEach(box => {
				if (box.$el._isRunningHint) {
					box.$el.parentElement.appendChild(box.$el);
				}
			});
			var last = this.$refs.chatBox[this.$refs.chatBox.length - 1];
			last.$el.scrollIntoView();
		},
		async clearConversation () {
			console.log('Clear Conversation');

			var result, over = this.conversation.current.forgive === 0 || this.conversation.current.forgive === 100;
			try {
				result = await sendRequest('/games', {
					event: 'reset',
					gid: GameID,
					ego: this.ego,
					over
				});
				console.log(result);
				if (!result.ok) {
					console.error(result.message);
					notify({
						title: "聊天记录本就为空",
						duration: 5000,
						type: "warn"
					});
					return;
				}
			}
			catch (err) {
				console.error(err);
				notify({
					title: "聊天记录清空失败",
					message: "请稍后重试，或者联系管理员。",
					duration: 5000,
					type: "failed"
				});
				return;
			}

			if (!!over) {
				let conversation = {};
				conversation.history = [];
				conversation.info = result.data;
				conversation.current = {};
				this.conversation = conversation;
			}
			else {
				this.conversation.history.splice(0);
				this.conversation.time = Date.now();
			}
			this.list.splice(0);
			for (let key in this.conversation.info.scores) {
				this.conversation.current[key] = this.conversation.info.scores[key];
			}
			this.addChat(5, '场景描述：\n' + this.conversation.info.desc);
			await DataCenter.set(DBNames.avatar, 'history', GameID, this.conversation);

			if (!!over) {
				notify({
					title: "开启新一轮情感危机",
					message: "上一轮情感考验已经结束，下面是一场新的考验，君请加油！",
					duration: 5000,
					type: "success"
				});
			}
			else {
				notify({
					title: "聊天记录已清空",
					message: "数字女友的记忆已经被充值到场景一开始，请重新开始哄你的女友！",
					duration: 5000,
					type: "success"
				});
			}
		},
		async onDownload () {
			var name = this.title.replace(/\s*[\(（][\w\W]+[\)）]\s*/gi, '');
			var model = this.title.replace(name, '').replace(/^\s*|\s*[\(\)（）]\s*|\s*$/gi, '');

			var inner = ['[title:]History of the conversation with ' + name + '[:title]'];
			var markdown = ["#\tHistory of the conversation with " + name];
			var filename = getTimeString(this.conversation.time);
			markdown.push('> Soul Model: ' + model + '\n> Updated at: ' + filename);
			inner.push('[center:]{block:start|w200}Soul Model: ' + model + '{block:end}[:center]\n[center:]{block:start|w200}Updated at: ' + filename + '{block:end}[:center]');
			markdown.push('\n-----\n');
			inner.push('-----');
			filename = GameID + '-' + filename.replace(/ /g, '-').replace(/\//g, '') + '.hst';

			var inSide = -1;
			this.conversation.history.forEach(item => {
				let ctx = '', inn = [];
				if (item[0] === 0) {
					let str;
					if (inSide !== 0) {
						markdown.push('##\tMe');
						inner.push('[right:][big:][bold:]Me[:bold][:big][:right]');
					}
					str = item[2] * 1;
					if (!isNaN(str) && str !== 0) {
						str = getTimeString(item[2]);
						ctx = '(' + str + ')\n';
						inn.push('[right:][italic:](' + str + ')[:italic][:right]');
					}
					str = item[1].replace(/^\s+|\s+$/g, '').replace(/\n{3,}/g, '\n\n');
					ctx = ctx + str;
					str = str.split('\n').map(l => '[right:]' + simpleParse(l) + '[:right]').join('\n');
					inn.push(str);
					inSide = 0;
				}
				else if (item[0] === 1) {
					let str;
					if (inSide !== 1) {
						markdown.push('##\t' + name);
						inner.push('[big:][bold:]' + name + '[:bold][:big]');
					}
					str = item[2] * 1;
					if (!isNaN(str) && str !== 0) {
						str = getTimeString(item[2]);
						ctx = '(' + str + ')\n';
						inn.push('[italic:](' + str + ')[:italic]');
					}
					str = item[1].replace(/^\s+|\s+$/g, '').replace(/\n{3,}/g, '\n\n');
					ctx = ctx + str;
					str = str.split('\n').map(l => simpleParse(l)).join('\n');
					inn.push(str);
					inSide = 1;
				}
				if (!!ctx) {
					markdown.push(ctx);
					inner.push(inn.join('\n'));
				}
			});
			markdown = markdown.join('\n\n');
			inner = inner.join('\n\n');
			inner = inner.split('\n').map(l => {
				if (!!l) return l;
				return '{block:start|h20}{block:end}';
			}).join('\n');

			var blob = new Blob([markdown], { type: 'text/plain' });
			var link = URL.createObjectURL(blob);
			var downloader = newEle('a');
			downloader.setAttribute('href', link);
			downloader.setAttribute('download', 'chatHistory.md');
			downloader.click();

			if (isExperiment) {
				await DataCenter.set(DBNames.record, 'avatar', filename, inner);
				notify({
					title: "聊天记录已经保存到本地",
					message: "你也可以在console中/SAIDAO/History/目录下查看边缘端记录。",
					duration: 5000,
					type: "success"
				});
			}
			else {
				notify({
					title: "聊天记录已经保存到本地",
					duration: 3000,
					type: "success"
				});
			}
		},
	}
};
</script>